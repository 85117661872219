// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-projects-template-js": () => import("./../../../src/templates/ProjectsTemplate.js" /* webpackChunkName: "component---src-templates-projects-template-js" */)
}

